import request, { RequestConfigDto } from './request'
import { QueryPageDto } from './dto'

// 获取被派遣单位列表
export interface DispatchDto {
  company_id: number
  create_time: string
  industry_id: number
  industry_name: string
  name: string
  occupation: { occupation_id: number; name: string; level: number }[]
  remark: string
  status: string
  is_archive: number
  'video': {
    'cover': string,
    'url': string
  }[]
}

interface GetDispatchReqDto extends QueryPageDto {
  status?: '10' | '20',
  policyId?: number
}

export function getDispatch (
    params: GetDispatchReqDto,
    config = {} as RequestConfigDto
): Promise<{ data: DispatchDto[]; total: number }> {
    config.params = params
    return request.get('/company/list', config)
}

// 删除被派遣单位 company/delete
export function dispatchDelete (data: {
  companyId: string | number
}): Promise<[]> {
    return request.post('/company/delete', data)
}

// 归档被派遣单位 company/archive
export function dispatchArchive (data: {
  companyId: string | number
}): Promise<[]> {
    return request.post('/company/archive', data)
}

// 添加被派遣单位
export function dispatchAdd (data: {
  name: string
  true_client_name: string
  content: string
  fileIds: string[]
}): Promise<[]> {
    return request.post('/company/add', data)
}
